import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import {
  findProcessDrawDetail,
  processCancelRequest,
  processCancelRequestConfirm,
} from '../../constants/utils';
import IconWarningRed from '../../assets/images/warning_red.png';
import IconWarningYellow from '../../assets/images/warning_yellow.png';

const UserDropdown = ({ document_info, selectedUserId, handleUserChange }) => {
  const handleSelectUser = (event) => {
    const userId = event.target.value; // セレクトボックスで選択された値
    handleUserChange(userId);
  };

  const renderSelectedUser = (selected) => {
    if (selected === 'all') {
      // 「全てのユーザー」が選択された場合も通常の文字スタイルで表示
      return (
        <span className="d-flex align-items-center">
          全てのユーザー
        </span>
      );
    }

    const selectedItem = document_info?.user_confirm_draws?.find(
      (item) => item.user?.id === selected
    );

    if (!selectedItem) {
      return selected; // 選択されたユーザーが見つからない場合
    }

    const { user, user_address_book, process, processedAt } = selectedItem;
    const userName = user_address_book?.user_name ?? user?.name;
    const processing = findProcessDrawDetail(process);
    let isWarningRed = false;
    if (process === processCancelRequest.type) isWarningRed = true;
    if (process === processCancelRequestConfirm.type) isWarningRed = false;

    return (
      <div className="d-flex align-items-center">
        <span className="pr-3 font-weight-bold">{userName} 様</span>
        <span className="pr-2">{processing?.title}</span>
        {(process === processCancelRequest.type || process === processCancelRequestConfirm.type) && (
          <Tooltip title={'コメント内容'} placement="top">
            <IconButton size="small">
              <img
                src={isWarningRed ? IconWarningRed : IconWarningYellow}
                alt="warning"
                width={20}
              />
            </IconButton>
          </Tooltip>
        )}
        <span>
          {processedAt
            ? moment(new Date(Date.parse(processedAt))).format('YYYY-MM-DD HH:mm:ss')
            : ''}
        </span>
      </div>
    );
  };

  return (
    <FormControl fullWidth variant="outlined">
      <InputLabel id="user-select-label">ユーザーを選択</InputLabel>
      <Select
        labelId="user-select-label"
        id="user-select"
        value={selectedUserId || 'all'}
        onChange={handleSelectUser}
        label="ユーザーを選択"
        displayEmpty
        renderValue={renderSelectedUser}
      >
        <MenuItem value="all">
          <div className="d-flex align-items-center">
            <span className="pr-3 font-weight-bold">全てのユーザー</span>
          </div>
        </MenuItem>
        {document_info?.user_confirm_draws?.map((item, index) => {
          let isWarningRed = false;
          const { processedAt, user, user_address_book, process, complain } =
            item ?? {};
          const userName = user_address_book?.user_name ?? user?.name;
          if (process === processCancelRequest.type) isWarningRed = true;
          if (process === processCancelRequestConfirm.type) isWarningRed = false;
          const processing = findProcessDrawDetail(process);

          return (
            <MenuItem key={index} value={user?.id}>
              <div className="d-flex align-items-center">
                <span className="pr-3 font-weight-bold">{userName} 様</span>
                <span className="pr-2">{processing?.title}</span>
                {(process === processCancelRequest.type || process === processCancelRequestConfirm.type) && (
                  <Tooltip title={'コメント内容'} placement="top">
                    <IconButton size="small">
                      <img
                        src={isWarningRed ? IconWarningRed : IconWarningYellow}
                        alt="warning"
                        width={20}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <span>
                  {processedAt
                    ? moment(new Date(Date.parse(processedAt))).format('YYYY-MM-DD HH:mm:ss')
                    : ''}
                </span>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default UserDropdown;